import moment from 'moment';
import ApiService from "@/services/api.service";
import {
    ADD_CLAIM,
    ADD_COVERAGE_DOCUMENT,
    ADD_LOCAL_POLICY,
    CLEAR_LOCAL_POLICIES,
    CLEAR_PROGRAMME,
    CLEAR_PROGRAMMES,
    SET_ACTIVITY_FEED,
    SET_IS_JAPAN_BUSINESS,
    SET_LOADING_LOCAL_POLICIES,
    SET_LOADING_PROGRAMMES,
    SORT_LOCAL_POLICY_LOCATIONS,
    SET_PROGRAMME,
    SET_PROGRAMMES,
    UPDATE_CONTACT,
    SET_COVERAGE_CURRENCY_TYPE,
    SET_FINANCE_CURRENCY_TYPE,
    SET_LOCATIONS_CURRENCY_TYPE,
    ADD_LOCATIONS,
    SET_LOADING_LOCATIONS,
    SET_LOADED_COVERAGE,
    SET_LOADING_COVERAGE,
} from "@/store/mutation-types";
import UnderwritingService from "@/services/underwriting.service";
import ClaimService from "@/services/claim.service";
import { POLICY_PAID_STATUS } from '@/constants';

export const underwriting = {
    state: () => ({
        programmes: [],
        localPolicies: [],
        claims: [],
        coverageDocuments: [],
        locations: [],
        activityFeed: [],
        loadingProgrammes: {
            type: Boolean,
            default: false
        },
        loadingLocalPolicies: {
            type: Boolean,
            default: false
        },
        coverageLoaded: {
            type: Boolean,
            default: false
        },
        loadingCoverage: {
            type: Boolean,
            default: false
        },
        loadingLocations: {
            type: Boolean,
            default: false
        },
        coverageSelectedCurrency: 'Master',
        financeSelectedCurrency: {
            type: String,
            default: 'Master'
        },
        locationsSelectedCurrency: {
            type: String,
            default: 'Master'
        },
        isJapanBusiness: {
            type: Boolean,
            default: false
        },
    }),
    mutations: {
        [SET_PROGRAMMES](state, payload) {
            state.programmes = payload.programmes;
        },
        [SET_PROGRAMME](state, { programme }) {
            const index = state.programmes.findIndex(existingProgramme => existingProgramme.id == programme.id);
            if (index < 0) {
                state.programmes.push(programme);
            } else {
                state.programmes[index] = programme;
            }
        },
        [ADD_LOCAL_POLICY](state, { localPolicy }) {
            if (localPolicy.countries?.length > 0 && typeof localPolicy.countries[0] === 'object') {
                localPolicy.countries = localPolicy.countries.map(country => country.country_id);
            }
            if (localPolicy.lines.length > 0 && typeof localPolicy.lines[0].country === 'object') {
                localPolicy.lines = localPolicy.lines.map(line => {
                    line.country = line.country.country_id;
                    return line;
                });
            }
            state.localPolicies.push(localPolicy);
        },
        [ADD_CLAIM](state, { claim }) {
            state.claims = state.claims.filter(currentClaim => currentClaim.claim_id!==claim.claim_id);
            state.claims.push(claim);
        },
        [ADD_COVERAGE_DOCUMENT](state, { document }) {
            state.coverageDocuments = state.coverageDocuments.filter(currentDocument => currentDocument.coverage_document_header_id!==document.coverage_document_header_id);
            state.coverageDocuments.push(document);
        },
        [ADD_LOCATIONS](state, { locations }) {
            state.locations = locations;
        },
        [CLEAR_PROGRAMMES](state) {
            state.programmes = [];
            state.localPolicies = [];
            state.locations = [];
            state.coverageDocuments = [];
            state.claims = [];
            state.coverageSelectedCurrency = 'Master';
        },
        [CLEAR_PROGRAMME](state, { programmeId }) {
            state.programmes = state.programmes.filter(programme => programme.id != programmeId)
            const removingLocalPolicies = state.localPolicies
                .filter(localPolicy => localPolicy.master_policy_id == programmeId)
                .map(localPolicy => localPolicy.local_policy_id);
            state.coverageDocuments = state.coverageDocuments
                .filter(coverageDocument => removingLocalPolicies.indexOf(coverageDocument.local_policy_id) === -1);
            state.claims = state.claims.filter(claim => removingLocalPolicies.indexOf(claim.local_policy_id) === -1);
        },
        [CLEAR_LOCAL_POLICIES](state, { master_policy_id, local_policy_id }) {
            if (master_policy_id === undefined) {
                state.localPolicies = [];
            } else {
                state.localPolicies = state.localPolicies.filter(lpol => !(lpol.master_policy_id == master_policy_id && (local_policy_id === undefined || lpol.local_policy_id == local_policy_id)));
            }
        },
        [SET_LOADING_PROGRAMMES](state, { loading }) {
            state.loadingProgrammes = loading;
        },
        [SET_LOADING_LOCAL_POLICIES](state, { loading }) {
            state.loadingLocalPolicies = loading;
        },
        [SET_LOADED_COVERAGE](state, { loading }) {
            state.coverageLoaded = loading;
        },
        [SET_LOADING_COVERAGE](state, { loading }) {
            state.loadingCoverage = loading;
        },
        [SET_LOADING_LOCATIONS](state, { loading }) {
            state.loadingLocations = loading;
        },
        [UPDATE_CONTACT](state, { id, contacts }) {
            const lPol = state.localPolicies.find(lPol => lPol.local_policy_id==id);
            for (let field in contacts.Client) {
                lPol.key_contacts['client_' + field] = contacts.Client[field].value;
            }
            for (let field in contacts.Broker) {
                lPol.key_contacts['broker_' + field] = contacts.Broker[field].value;
            }
        },
        [SET_ACTIVITY_FEED](state, { activityFeed }) {
            state.activityFeed = activityFeed;
        },
        [SET_IS_JAPAN_BUSINESS](state, { isJapanBusiness }) {
            state.isJapanBusiness = isJapanBusiness;
        },
        [SORT_LOCAL_POLICY_LOCATIONS](state, {dir, policyId}) {

            let policies = [];

            state.localPolicies.forEach((policy)=> {
                if (policy.local_policy_id == policyId) {
                    policy.locations.sort((a,b) => {
                        if (a['total_location_sum_insured'] <= b['total_location_sum_insured']) { return 1; }
                        if (a['total_location_sum_insured'] >= b['total_location_sum_insured']) { return -1;}
                        return 0;
                    });
                    if (dir === 'desc') { policy.locations.reverse(); }
                }
                policies.push(policy);
            });
            state.localPolicies = policies;
        },
        [SET_COVERAGE_CURRENCY_TYPE](state, {currencyType}) {
            state.coverageSelectedCurrency = currencyType;
        },
        [SET_FINANCE_CURRENCY_TYPE](state, {currency_type}) {
            state.financeSelectedCurrency = currency_type;
        },
        [SET_LOCATIONS_CURRENCY_TYPE](state, {currencyType}) {
            state.locationsSelectedCurrency = currencyType;
        }
    },
    actions: {
        async getCountries() {
            try {
                let { data } = await ApiService.get(
                process.env.VUE_APP_API_ENDPOINT + "/country",
                );
                return data;
            } catch (err) {
                console.log("AXIOS error");
                console.error(err);
            }
        },
        async sortCountries({ rootGetters, commit }, { dir, policyId }) {
            try {
                commit(SORT_LOCAL_POLICY_LOCATIONS, {dir, policyId});
            } catch (err) {
                console.log("AXIOS error");
                console.error(err);
            }
        },
        async getLocalPolicies({ rootGetters, commit }, { master_policy_id, local_policy_id, currency_type }) {
            // 2021-09-14 - Removed code to skip data refresh if already available in store

            commit(SET_LOADING_LOCAL_POLICIES, { loading: true });
            commit(CLEAR_LOCAL_POLICIES, { master_policy_id, local_policy_id });
            commit(SET_FINANCE_CURRENCY_TYPE, { currency_type });

            if(!currency_type) {
                currency_type = this.state.financeSelectedCurrency
            }

            const { localPolicies, isJapanBusiness } = await UnderwritingService.getLocalPoliciesForProgramme(
                master_policy_id,
                local_policy_id ?? null,
                rootGetters.selectedClientId,
                currency_type
            );

            if (!localPolicies || localPolicies.length === 0) {
                console.log("No local policies found for master_policy_id: " + master_policy_id);
                commit(SET_LOADING_LOCAL_POLICIES, { loading: false });
                return false;
            }

            commit(SET_IS_JAPAN_BUSINESS, { isJapanBusiness });

            for (let localPolicy of localPolicies) {
                commit(ADD_LOCAL_POLICY, { localPolicy });
            }
            commit(SET_LOADING_LOCAL_POLICIES, { loading: false });
            return true;
        },
        async loadClaim({rootGetters, commit}, { claim_id }) {
            // 2021-09-14 - Removed code to skip data refresh if already available in store

            const { claim } = await ClaimService.getClaimByID(rootGetters.selectedClientId, claim_id);
            if (!claim) {
                console.log("No claim found with ID: " + claim_id);
                return false;
            }
            commit(ADD_CLAIM, {claim});
            return true;
        },
        async loadClaimsForLocalPolicy({ rootGetters, commit }, { local_policy_id }) {
            // 2021-09-14 - Removed code to skip data refresh if already available in store

            const { claims } = await ClaimService.getClaimsForLocalPolicy(rootGetters.selectedClientId, local_policy_id);
            if (claims.length === 0) {
                return;
            }
            for (const claim of claims) {
                commit(ADD_CLAIM, { claim });
            }
        },
        async loadCoverageDocument({rootGetters, commit}, { programmeId, localPolicyId, currencyType }) {
            // 2021-09-14 - Removed code to skip data refresh if already available in store
            commit(SET_LOADED_COVERAGE, { loading: false });
            commit(SET_LOADING_COVERAGE, { loading: true });

            if(!currencyType) {
                currencyType = rootGetters.coverageSelectedCurrency
            } else {
                commit(SET_COVERAGE_CURRENCY_TYPE, { currencyType });
            }

            const { document } = await UnderwritingService.getCoverageDocument(rootGetters.selectedClientId, programmeId, localPolicyId, currencyType);
            if (!document) {
                console.error("No Coverage Document found for MPol ID", programmeId, "and LPol ID", localPolicyId);
                commit(SET_LOADING_COVERAGE, { loading: false });
                return false;
            }
            commit(ADD_COVERAGE_DOCUMENT, { document });
            commit(SET_LOADED_COVERAGE, { loading: true });
            commit(SET_LOADING_COVERAGE, { loading: false });
            return true;
        },
        async loadCoverageTemplate({rootGetters, commit}, { programmeId, currencyType }) {
            commit(SET_LOADED_COVERAGE, { loading: false });
            commit(SET_LOADING_COVERAGE, { loading: true });

            if(!currencyType) {
                currencyType = rootGetters.coverageSelectedCurrency
            } else {
                commit(SET_COVERAGE_CURRENCY_TYPE, { currencyType });
            }

            const { document } = await UnderwritingService.getCoverageTemplate(rootGetters.selectedClientId, programmeId, currencyType);
            if (!document) {
                console.error("No Coverage Template found for MPol ID", programmeId);
                commit(SET_LOADING_COVERAGE, { loading: false });
                return false;
            }
            commit(ADD_COVERAGE_DOCUMENT, { document });
            commit(SET_LOADED_COVERAGE, { loading: true });
            commit(SET_LOADING_COVERAGE, { loading: false });
            return true;
        },
        async loadLocations({state, rootGetters, commit}, { programmeId, localPolicyId, currencyType }) {
            commit(SET_LOADING_LOCATIONS, { loading: true });
            commit(SET_LOCATIONS_CURRENCY_TYPE, { currencyType });

            if (!currencyType) {
                currencyType = this.state.locationsSelectedCurrency;
            }

            const {locations} = await UnderwritingService.getLocations(rootGetters.selectedClientId, programmeId, localPolicyId, currencyType);
            if (!locations) {
                console.error("No Locations found for MPol ID", programmeId, "and LPol ID", localPolicyId);
                return false;
            }
            
            commit(ADD_LOCATIONS, {locations});
            commit(SET_LOADING_LOCATIONS, { loading: false });
            return true;
        },
        claimExists({state}, {claim_id}) {
            return state.claims.find(claim => claim.claim_id==claim_id)!==undefined;
        },
        async getProgrammes({ commit, rootGetters }) {
            commit(CLEAR_PROGRAMMES);
            commit(SET_LOADING_PROGRAMMES, { loading: true });
            const { programmes } = await UnderwritingService.getProgrammes(rootGetters.selectedClientId);
            if (!programmes) {
                // There was an error while getting programmes from the API
                console.log("Didn't receive any programmes...");
                return;
            }
            const statuses = [
                ['On Track', 'info'],
                ['Requires Attention', 'warning'],
                ['Active', 'success'],
                ['Expired', 'secondary']
            ];
            // TODO: Refactor this...
            const classes = [
                ['Property', 'building'],
                ['Marine', 'water'],
                ['Financial Lines', 'dollar-sign'],
                ['Liability', 'users'],
                ['Engineering', 'cogs']
            ];
            for (let programme of programmes) {
                const expiry = moment(String(programme['expiry']));
                if (expiry.diff(moment()) < 0) {
                    programme['status'] = statuses[3][0];
                    programme['statusType'] = statuses[3][1]
                } else {
                    programme['status'] = statuses[1][0];
                    programme['statusType'] = statuses[1][1]
                }
                // programme['status'] = status[0];
                // programme['statusType'] = status[1];
                programme['highlighted'] = false;
                const c = classes.find(cl => cl[0] == programme.class);
                programme['icon'] = 'users';
                if (c) {
                    programme['icon'] = c[1];
                }
                // for (let _ = 0; _ < programme.lpolCount; _++) {
                //     dispatch('createLocalPolicy', {mPolId: programme.id, lPolId: (programme.id * 100) + _});
                // }
            }

            this.state.financeSelectedCurrency = 'Master';
            this.state.coverageSelectedCurrency = 'Master';
            this.state.locationsSelectedCurrency = 'Master';

            programmes.sort((a, b) => (a.name < b.name) ? -1 : 1);
            commit(SET_PROGRAMMES, { programmes });
            commit(SET_LOADING_PROGRAMMES, { loading: false });
        },
        async getProgramme({ commit, rootGetters }, { programmeId }) {
            commit(SET_LOADING_PROGRAMMES, { loading: true });
            commit(CLEAR_LOCAL_POLICIES, { master_policy_id: programmeId });
            commit(CLEAR_PROGRAMME, { programmeId });
            const { success, programme } = await UnderwritingService.getProgramme(rootGetters.selectedClientId, programmeId);
            if (!success || typeof programme === 'undefined') {
                commit(SET_LOADING_PROGRAMMES, { loading: false });
                return false;
            }
            commit(SET_PROGRAMME, { programme })
            commit(SET_LOADING_PROGRAMMES, { loading: false });
            return true;
        },
        async getActivityFeed({ commit, rootGetters }, { timeframe }) {
            const activityFeed = await UnderwritingService.getActivityFeed(rootGetters.selectedClientId, timeframe);

            if (!activityFeed) {
                console.log('Unable to load activity feed for client ' + rootGetters.selectedClientId + ' for timeframe ' + timeframe);
                return false;
            }

            commit(SET_ACTIVITY_FEED, { activityFeed })
            return true;
        },
        programmeExists({ state }, { programmeId }) {
            const prog = state.programmes.find(prog => prog.id==programmeId);
            return (typeof prog !== 'undefined');
        },
        localPolicyExists({ state }, { localPolicyId }) {
            const lPol = state.localPolicies.find(lPol => lPol.local_policy_id==localPolicyId);
            return (typeof lPol !== 'undefined');
        },
        updateContacts({ dispatch, commit }, { id, contacts }) {
            const lPol = dispatch('localPolicyExists', id);
            if (typeof lPol === 'undefined') {
                console.log(
                    "Couldn't find a local policy with ID " + id
                    + " while trying to update contact details"
                );
                return;
            }

            commit(UPDATE_CONTACT, { id, contacts })
        }
    },
    getters: {
        programmes: state => state.programmes,
        activeProgrammes: state => state.programmes.filter(programme => programme.status!=='Expired'),
        expiredProgrammes: state => state.programmes.filter(programme => programme.status==='Expired'),
        localPolicies: state => state.localPolicies,
        loadingProgrammes: state => state.loadingProgrammes,
        loadingLocalPolicies: state => state.loadingLocalPolicies,
        loadingCoverage: state => state.loadingCoverage,
        coverageLoaded: state => state.coverageLoaded,
        loadingLocations: state => state.loadingLocations,
        claims: state => state.claims,
        coverageDocuments: state => state.coverageDocuments,
        locations: state => state.locations,
        activityFeed: state => state.activityFeed,
        financeSelectedCurrency: state => state.financeSelectedCurrency,
        locationsSelectedCurrency: state => state.locationsSelectedCurrency,
        coverageSelectedCurrency: state => state.coverageSelectedCurrency,
        isJapanBusiness: state => state.isJapanBusiness,
        referenceTransaction: state => (localPolicyId) => {
            const lPol = state.localPolicies.find(lPol => lPol.local_policy_id == localPolicyId);
            if (typeof lPol === 'undefined') {
                return undefined;
            }
            return lPol.lines.find(line => line.flag_roe_reference_tx); 
        },
        policyPaidStatus: (_, getters) => (localPolicyId) => {
            const referenceTransaction = getters.referenceTransaction(localPolicyId);
            if (typeof referenceTransaction === 'undefined'
                || (referenceTransaction.flag_payment_made == 0 && (parseFloat(referenceTransaction.dec_partial_collection) == 0.0 || referenceTransaction.dec_partial_collection == null))
            ) {
                return POLICY_PAID_STATUS.UNPAID;
            }
            if (referenceTransaction.flag_payment_made == 1) {
                return POLICY_PAID_STATUS.PAID;
            }

            return POLICY_PAID_STATUS.PARTIAL;
        },
    }
};
