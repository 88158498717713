import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from "vuex-persist";
// Module imports
import { underwriting } from './underwriting.js'
import { user } from './user.js'
import { countries } from './countries.js'

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
});

export default new Vuex.Store({
    modules: {
        underwriting,
        user,
        countries
    },
    state: {
        appVersion: '0.1.0',
    },
    mutations: {
        updateVersionNumber(state, { version }) {
          state.appVersion = version;
        },
    },
    actions: {
        async loadState({ dispatch }) {
            await dispatch("loadCountries");
        },
        versionChanged({state, commit}) {
          return new Promise((resolve, _) => {
            if (process.env.VUE_APP_VERSION !== state.appVersion) {
              console.log("Version updated from '%s' to '%s'", state.appVersion, process.env.VUE_APP_VERSION);

              commit('updateVersionNumber', { version: process.env.VUE_APP_VERSION });

              resolve(true);
            } else {
              console.log("Version is already '%s'", state.appVersion);
            }
            resolve(false);
          })
        }
    },
    strict: process.env.NODE_ENV !== "production",
    plugins: [
        vuexLocal.plugin,
    ]
});
