const FeaturesMixin = {
    methods: {
        isClient(clientKeys) {
            if (clientKeys?.constructor !== Array) {
                clientKeys = [clientKeys];
            }
            
            for (let clientKey of clientKeys) {
                if (process.env.VUE_APP_ICEDE_CLIENT === clientKey) {
                    return true
                }
            }
            return false
        },
        getiCedeClientName() {
            let clientKeyName = '';
            switch(process.env.VUE_APP_ICEDE_CLIENT) {
                case process.env.VUE_APP_ICEDE_CLIENT_TMG:
                    clientKeyName = 'Tokio Marine';
                    break;
                case process.env.VUE_APP_ICEDE_CLIENT_STARR:
                    clientKeyName = 'Starr';
                    break;
                case process.env.VUE_APP_ICEDE_CLIENT_SOMPO:
                    clientKeyName = 'Sompo';
                    break;
            }

            return clientKeyName;
        },
        getLoginFormLabel() {
            switch (process.env.VUE_APP_ICEDE_CLIENT) {
                case process.env.VUE_APP_ICEDE_CLIENT_TMG:
                    return 'Email address';
                default:
                    return 'Email address';
            }
        },
        getLoginFormInputType() {
            switch (process.env.VUE_APP_ICEDE_CLIENT) {
                case process.env.VUE_APP_ICEDE_CLIENT_TMG:
                    return 'text';
                default:
                    return 'email';
            }
        },
        getLoginFormAutocomplete() {
            switch (process.env.VUE_APP_ICEDE_CLIENT) {
                case process.env.VUE_APP_ICEDE_CLIENT_TMG:
                    return 'email';
                default:
                    return 'username email';
            }
        },
        usingClaimsFeatures() {
            return !this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG);
        },
        showPremiumDataInLists() {
            return !this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG);
        },
        showHandlersInLists() {
            return !this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG);
        },
        getClientSpecificTitle() {
            if (this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG)) {
                return "TMGO Client Portal";
            }
            return "Client Portal";
        },
        getClientExtraLogos() {
            if (this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG)) {
                return [
                    require('@/assets/img/icede-client/tmg/logos/tmnf_main_transp.png'),
                    require('@/assets/img/icede-client/tmg/logos/sn_main_transp.png'),
                    require('@/assets/img/icede-client/tmg/logos/hcc_main_transp.png'),
                ];
            }
            return [];
        },
        wikiEnabled() {
            return !this.isClient(process.env.VUE_APP_ICEDE_CLIENT_DEFAULT);
        },
        hidePremiumUntilPolicyFinalised() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG);
        }
    }
}

export default FeaturesMixin
