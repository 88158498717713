<template>
  <div id="app">
    <notifications></notifications>
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App',
  mounted() {
    // Vuex store schema changes or data reloads after a code/version update should be done here
    this.$store.dispatch('versionChanged').then(changed => {
      if (changed) {
        if (this.$store.getters.isLoggedIn) {
          // Logout the user if the version has changed
          this.$store.dispatch('logout');
          this.$router.push({ name: "Login" });
        } else {
          // Just ensure local storage is cleared if not logged in
          localStorage.clear();
        }
      }
    });
  }
}
</script>
