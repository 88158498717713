import {
    CLEAR_PROGRAMMES,
    CLEAR_USER,
    SET_SELECT_CLIENT_ID,
    SET_SINGLE_CLIENT,
    SET_TWO_FACTOR_METHOD,
    SET_USER,
    UPDATE_PASSWORD_EXPIRED,
    UPDATE_TWO_FACTOR_STATUS,
    ACCEPT_TERMS, SET_FAILED_LOGIN_ERROR_MESSAGE
} from "@/store/mutation-types";
import AuthService from "@/services/auth.service";
import * as Sentry from "@sentry/vue"

export const user = {
    state: () => ({
        user: {},
        isLoggedIn: false,
        failedLoginErrorMessage: '',
        passwordExpired: false,
        selectedClientId: 0,
        twoFactor: {
            twoFactorTermsAccepted: false,
            twoFactorMethod: 'Email',
            twoFactorAuthNeeded: true,
            twoFactorSetup: false
        }
    }),
    mutations: {
        [SET_USER](state, { user, passwordExpired, twoFactor }) {
            state.user = user;
            state.isLoggedIn = true;
            state.passwordExpired = passwordExpired;
            state.twoFactor = twoFactor
        },
        [CLEAR_USER](state) {
            state.user = {};
            state.isLoggedIn = false;
            state.selectedClientId = 0;
        },
        [SET_SELECT_CLIENT_ID](state, { clientId }) {
            state.selectedClientId = clientId;
        },
        [SET_SINGLE_CLIENT](state) {
            if (state.user?.clients?.length) {
                state.selectedClientId = state.user.clients[0].client_id;
            }
        },
        [UPDATE_PASSWORD_EXPIRED](state, { passwordExpired }) {
            state.passwordExpired = passwordExpired;
        },
        [UPDATE_TWO_FACTOR_STATUS](state, { twoFactorSetup, twoFactorAuthNeeded }) {
            state.twoFactor.twoFactorSetup = twoFactorSetup;
            state.twoFactor.twoFactorAuthNeeded = twoFactorAuthNeeded;
        },
        [ACCEPT_TERMS](state) {
            state.twoFactor.twoFactorTermsAccepted = true;
        },
        [SET_TWO_FACTOR_METHOD](state, { method }) {
            state.twoFactor.twoFactorMethod = method;
        },
        [SET_FAILED_LOGIN_ERROR_MESSAGE] (state, {errorMessage}) {
            state.failedLoginErrorMessage = errorMessage;
        }
    },
    actions: {
        loginUser({ commit }, { data }) {
          commit(
            SET_USER,
            { user: data.user, passwordExpired: data.passwordExpired, twoFactor: data.twoFactor }
          );
          Sentry.setUser({
            id: data.user.client_broker_contact_id,
            email: data.user.email,
            ip_address: "{{auto}}"
          });
        },
        async login({ commit, dispatch, state }, { email, password }) {
            commit(CLEAR_USER);
            commit(CLEAR_PROGRAMMES);
            localStorage.clear();
            let response = [];
            try {
                const data = await AuthService.login(email, password);
                if (data && data.success) {
                    dispatch('loginUser', { data });
                } else {
                    commit(SET_FAILED_LOGIN_ERROR_MESSAGE, {errorMessage: data.message});
                }
            } catch (err) {
                console.log("Error on login via ajax");
                console.error(err);
                Sentry.captureException(err);
            }
            return response = {'success': state.isLoggedIn, 'message': state.failedLoginErrorMessage};
        },
        async loginWithToken({ dispatch }, { token }) {
          try {
              let data = await AuthService.loginWithToken(token);
                
              if (data.success) {
                  dispatch("loginUser", { data });
              } else {
                  console.error("loginWihToken failed:", data);
              }
          } catch (err) {
            console.error(err);
            Sentry.captureException(err);
            return false;
          }
          return true;
        },
        async logout({ commit }) {
            commit(CLEAR_USER);
            commit(CLEAR_PROGRAMMES);
            localStorage.clear();
            Sentry.setUser(null);
            try {
                return await AuthService.logout();
            } catch (err) {
                console.log("Error on logout via ajax");
                console.error(err);
            }
        },
        async updatePassword({ commit }, {currentPassword, newPassword}) {
            try {
                const data = await AuthService
                    .changePassword(currentPassword, newPassword);
                if (data.success) {
                    commit(UPDATE_PASSWORD_EXPIRED, {passwordExpired: false});
                }
                return data;
            } catch (err) {
                console.log("Error when updating password via ajax");
                console.error(err);
            }
        },
        async setPassword({ commit }, {userHash, password}) {
            try {
                const data = await AuthService
                    .setAccountPassword(userHash, password);
                if (data !== undefined && data.success) {
                    if (data && data.success) {
                        commit(
                            SET_USER,
                            {
                                user: data.user,
                                passwordExpired: data.passwordExpired,
                                twoFactor: data.twoFactor
                            }
                        );
                    }
                }
                return data;
            } catch (err) {
                console.log("Error when setting account password via ajax");
                console.error(err);
            }
        },
        async authenticateTwoFactor({ commit }, {oneTimePassword}) {
            try {
                const data = await AuthService.authenticate(oneTimePassword);
                if (data.success) {
                    commit(UPDATE_TWO_FACTOR_STATUS, {twoFactorSetup: true, twoFactorAuthNeeded: false});
                }
                return data;
            } catch (err) {
                console.log("Error when updating password via ajax");
                console.error(err);
            }
        },
        async acceptTerms({ commit }) {
            try {
                const data = await AuthService.acceptTerms();
                if (data.success) {
                    commit(ACCEPT_TERMS);
                }
                return data;
            } catch (err) {
                console.log("Error when accepting terms via ajax");
                console.error(err);
            }
        }
    },
    getters: {
        user: state => state.user,
        offices: state => state.user.offices,
        isLoggedIn: state => state.isLoggedIn,
        passwordExpired: state => state.passwordExpired,
        selectedClientId: state => state.selectedClientId,
        clients: state => state.user.clients,
        client: state => state.user.clients?.find(c => c.client_id == state.selectedClientId) ?? {},
        twoFactorSetup: state => state.twoFactor?.twoFactorSetup,
        twoFactorAuthNeeded: state => state.twoFactor.twoFactorAuthNeeded,
        twoFactorMethod: state => state.twoFactor.twoFactorMethod,
        shouldGotoTwoFactor: state => (state.twoFactor?.twoFactorAuthNeeded || !state.twoFactor?.twoFactorSetup),
        shouldGotoTerms: state => !state.twoFactor?.twoFactorTermsAccepted,
        termsAccepted: state => state.twoFactor?.twoFactorTermsAccepted,
    }
};
