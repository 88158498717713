<template>
  <div>
    <button class="btn btn-success btn-xs d-block" @click="printStorage"><i class="fa fa-print"></i>&nbsp;Log Storage</button>
    <small>
      <label id="local-storage-label">Local Storage Used <strong>{{ localStorageSizeMB }}MB</strong> of {{ maxCapacity / 1024 / 1024 }}MB
        <base-progress :value="storageCapacityUsedPc" type="primary" />
      </label>
    </small>
  </div>
</template>

<script>
import BaseProgress from '@/components/BaseProgress.vue'
export default {
  name: 'StorageUsage',
  components: {
    BaseProgress,
  },
  data: () => ({
    maxCapacity: 10 * 1024 * 1024, // 10MB
    // How often the storage used value is updated in secconds
    // The local storage is not reactive so we need to poll
    updateFrequency: 5000,
    localStorageSize: 0,
  }),
  computed: {
    localStorageSizeMB() {
      return parseFloat(new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 })
        .format(this.localStorageSize / 1024 / 1024));
    },
    storageCapacityUsedPc() {
      return parseFloat(new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 })
        .format((this.localStorageSize / this.maxCapacity) * 100));
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.$forceUpdate();
    next();
  },
  updated() {
    // this.$devLog('StorageUsage omponent has been updated');
  },
  methods: {
    printStorage() {
      // Get the Vuex store state
      let storeState = this.$store.state.underwriting.localPolicies;

      if (storeState.length === 0) {
        console.log('No data in the Vuex store');
        return;
      }

      // Initialize an object to store the sizes
      let sizes = {};

      // Iterate over each item in the Vuex store
      for (let item in storeState[2]['lines'][0]) {
        // Convert the item to a JSON string and calculate its length
        const stringified = JSON.stringify(storeState[2]['lines'][0][item]);
        if (stringified === undefined) {
          console.log('stringified is undefined', item, storeState[item]);
          continue;
        }
        let size = (stringified.length * 2) / 1024;
        
        /*if (size < 0.1) {
          continue;
        }*/

        // Store the size in the sizes object round to 2.d.p
        sizes[item] = parseFloat(size.toFixed(4));
      }

      // Sort the sizes object by size in descending order
      let sortedSizes = Object.entries(sizes).sort((a, b) => b[1] - a[1]);

      if (sortedSizes.length === 0) {
        console.log('No data of significant size in the Vuex store');
        return;
      }

      // Log "Storage Sizes" in larger text;
      /*console.log("%cStorage Sizes", "font-size: large;");
      sortedSizes.forEach((item) => {
        console.log(`${item[0]}: ${item[1]} KB`);
      });*/
      // Display the values in the largest item
      console.log(
        'Largest item:',
        sortedSizes[0][0],
        sortedSizes[0][1],
        'KB',
        storeState[2]['lines'][0][sortedSizes[0][0]],
      );
      console.log(
        'Master rate:',
        storeState[2]['lines'][0].master_currency_rate,
        'Transaction rate:',
        storeState[2]['lines'][0].currency_rate,
      );
    },
    updateStorageUsed() {
      this.setLocalStorageSize();
      setTimeout(() => {
        this.updateStorageUsed();
      }, 4000);
    },
    setLocalStorageSize() {
      let total = 0;
      for(let x in window.localStorage) {
          let amount = (window.localStorage[x].length * 2);
          if(!isNaN(amount) && Object.prototype.hasOwnProperty.call(window.localStorage, x)) {
              total += amount;
          }
      }
      this.localStorageSize = total;
    },
  },
  mounted() {
    this.updateStorageUsed();
  }
}
</script>

<style>

</style>
