import Vue from 'vue'
import App from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue'
// import { BrowserTracing} from '@sentry/tracing'

let bSentryActive = false;
// Sentry - Error logging
if (process.env.VUE_APP_API_ENDPOINT !== undefined
    && process.env.VUE_APP_API_ENDPOINT !== ''
    && process.env.VUE_APP_SENTRY_DSN !== undefined
    && process.env.VUE_APP_SENTRY_DSN !== ''
) {
    Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        logErrors: true,
        environment: location.hostname,
        integrations: [
            // new BrowserTracing({
            //     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            //     tracingOrigins: [process.env.VUE_APP_API_ENDPOINT, /^\//],
            // }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
    bSentryActive = true;
}

// Argon Template bits still used...
import './registerServiceWorker'
import globalComponents from "./plugins/globalComponents";
import NotificationPlugin from "@/components/NotificationPlugin/index"
Vue.use(globalComponents);
Vue.use(NotificationPlugin);

// import ArgonDashboard from './plugins/argon-dashboard'
// Vue.use(ArgonDashboard);

// Bootstrap Plugin
import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);

// iCede custom SCSS
import "@/assets/scss/iCede.scss";

Vue.config.productionTip = false;

// Font awesome
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

// Vue Font Awesome
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Including the standard bootstrap css will override the template's styles... BREAKING EVERYTHING
// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Vuex Store
import store from './store';

if (bSentryActive) {
    if (store.getters.isLoggedIn) {
        const user = store.getters.user;
        Sentry.setUser({id: user.client_broker_contact_id, email: user.email, ip: "{{auto}}"});
    } else {
        Sentry.setUser(null);
    }
}

import { formatThousands } from "./utils/numbers";

// Thousands separator filter
Vue.filter('thousands', formatThousands);

// TODO: Rewrite these without moment.js
// Date format filter
import moment from 'moment';
Vue.filter('formatDateUK', function (value) {
    return moment(String(value)).format('D MMM YYYY');
});
Vue.filter('imageSource', function (imageName) {
    return process.env.VUE_APP_API_ENDPOINT + '/image/' + imageName;
});
Vue.mixin({
    methods: {
        formatDateUKMixin: function (rawDate) {
            return moment(String(rawDate)).format('D MMM YYYY');
        },
        formatThousandsMixin: formatThousands,
        liveTextMixin: function(inception, expiry) {
            if (!moment(inception).isValid() || !moment(expiry).isValid()) {
                return ' - ';
            }
            if (moment(inception).isAfter(new Date())) {
                return 'Not live';
            } else if (moment(inception).isBefore(new Date()) && moment(expiry).isAfter(new Date())) {
                return 'Live';
            } else {
                return 'Expired';
            }
        },
    }
})

// Dev log for debugging
Vue.prototype.$devLog = function(...args) {
  if (process.env.NODE_ENV !== "production") {
    // add DEV: to the start of the log message
    args[0] = "DEV ONLY: " + args[0];
    console.log.apply(console, args);
  }
}

if (process.env.VUE_APP_ICEDE_CLIENT === process.env.VUE_APP_ICEDE_CLIENT_AVIVA) {
    import(`./assets/css/aviva.css`).catch(console.error);
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

// API Service
import ApiService from './services/api.service';
ApiService.init(process.env.VUE_APP_API_ENDPOINT);

import AuthService from './services/auth.service';
// Check auth status - send to login if not authed and not already there!
let request = AuthService.ping();
request.then(response => {
    if (response && response.status !== 200) {
        console.log('success but openRoute:', router.currentRoute.openRoute);
        if (!router.currentRoute.meta?.openRoute) {
            router.push({name: 'Login'});
        }
    }
}).catch(() => {
    console.log('catch and openRoute:', router.currentRoute.openRoute);
    if (!router.currentRoute.meta?.openRoute) {
        router.push({name: 'Login'});
    }
});
